<template>
    <div class="header">
        <div class="headerLeft">
            <router-link :to="'/home'">
                <img
                    class="logo"
                    src="@/assets/img/logo.png"
                    alt=""
                />
            </router-link>
            <div class="site">
<!--                <span @click="showText">流派</span>-->
<!--                <span @click="showText">排行</span>-->
<!--                <span @click="showText">百科</span>-->
            </div>
        </div>
        <div class="headerRight">
            <!--            <searchBar/>-->
            <img
                class="review_add"
                src="@/assets/img/review_add.svg"
                alt=""
                @click="addAlbum()"
            />
            <el-dropdown>
                <span v-if="isNotEmpty(userId)">
                   <img
                       class="user-face"
                       :src="this.userFace"
                       alt=""
                   />
                </span>
                <span
                    class="username"
                    v-else
                    @click="go2Login"
                >
                    登录
                </span>

                <template #dropdown v-if="isNotEmpty(userId)">
                    <el-dropdown-menu>
                        <el-dropdown-item @click="go2Space">
                            个人中心
                        </el-dropdown-item>
                        <el-dropdown-item @click="logout">
                            退出登录
                        </el-dropdown-item>
                    </el-dropdown-menu>
                </template>
            </el-dropdown>
        </div>
    </div>
    <el-dialog
        v-model="uploadAlbumDialog"
        title="添加专辑"
        width="300px"
        :before-close="handleClose"
    >
        <el-input
            v-model="url"
            placeholder="请输入专辑地址或分享链接"
            autosize
            type="textarea"
        />
        <div>
            <div
                style="margin-top: 10px; margin-bottom: 10px; font-size: larger"
            >
                支持平台：
            </div>
            <div>
                <img
                    style="padding-right: 10px"
                    :src="ncmLogo"
                    width="25"
                    height="25"
                    alt=""/>
                <img
                    style="padding-right: 10px"
                    :src="qqMusicLogo"
                    width="25"
                    height="25"
                    alt=""/>
                <img
                    style="padding-right: 10px"
                    :src="spotifyLogo"
                    width="25"
                    height="25"
                    alt=""/>
                <img
                    style="padding-right: 10px"
                    :src="bandcampLogo"
                    width="25"
                    height="25"
                    alt=""/>
            </div>
        </div>
        <template #footer>
            <span class="dialog-footer">
                <el-button
                    @click="handleClose"
                >取消</el-button
                >
                <el-button type="primary" @click="getAlbumInfo">
                    确认
                </el-button>
            </span>
        </template>
    </el-dialog>
    <el-dialog
        v-model="submitAlbumDialog"
        title="专辑信息"
        width="300px"
        :before-close="handleClose2"
    >
        <div class="album-info-area">
            <img :src="resolvedAlbum.coverLink" width="250" height="250" alt=""/>
            <div style="height: auto">
                <div class="album-info">
                    <span>{{ resolvedAlbum.albumName }}</span>
                </div>
                <div class="album-info">
                    <span
                        v-for="(artist, index) in resolvedAlbum.artistNameList"
                        :key="index"
                    >{{ artist }}<span v-if="index <  resolvedAlbum.artistNameList.length - 1">/&nbsp;</span>
                    </span>

                </div>
            </div>
        </div>
        <template #footer>
            <span class="dialog-footer">
                <el-button
                    @click="handleClose2"
                >取消</el-button
                >
                <el-button type="primary" @click="submitAlbum">
                    确认
                </el-button>
            </span>
        </template>
    </el-dialog>
</template>

<script>
import {checkLogin, getUserFace, getUserId, getUserName} from "@/utils/project-utils";
import {ElLoading} from "element-plus";
import searchBar from "@/components/tiny/SearchBar.vue";
import {showMessage} from "@/utils/ui/message";
import {getAlbumInfoByUrl} from "@/api/info";
import {addAlbum} from "@/api/album";
import {isNotEmpty} from "@/utils/common-utils";

export default {
    name: "Header",
    components: {
        searchBar,
    },
    data() {
        return {
            userId: getUserId(),
            userName: getUserName(),
            userFace: getUserFace(),
            uploadAlbumDialog: false,
            submitAlbumDialog: false,
            url: "",
            resolvedAlbum: {
                albumName: "",
                coverLink: "",
                labelName: "",
                albumType: 1,
                releaseDate: "",
                artistNameList: [],
                albumLinkList: [],
                trackList: [],
            },
            ncmLogo: require("@/assets/img/platform-logo/ncm.png"),
            qqMusicLogo: require("@/assets/img/platform-logo/qq-music.png"),
            bandcampLogo: require("@/assets/img/platform-logo/bandcamp.png"),
            spotifyLogo: require("@/assets/img/platform-logo/spotify.png"),
        };
    },
    created() {
        this.firstLoad();
    },
    methods: {
        isNotEmpty,
        firstLoad() {
            if (checkLogin()) {
                this.validation();
            }
        },
        validation() {
        },
        go2Login() {
            if (!checkLogin()) {
                this.$router.push("/login");
            }
        },
        go2Space() {
            if (checkLogin()) {
                this.$router.push({
                    path: "/space/" + localStorage.getItem("userId"),
                });
            }
        },
        logout() {
            showMessage("欢迎再来哦~小海獭会想你的", "success")
            localStorage.removeItem("token");
            localStorage.removeItem("userId");
            localStorage.removeItem("userName");
            localStorage.removeItem("userFace");
            setTimeout(() => {
                this.$router.go(0)
            }, 1000);
        },
        addAlbum() {
            if (!checkLogin()) {
                return showMessage("请先登录", "error")
            }
            this.uploadAlbumDialog = true;
        },
        getAlbumInfo() {
            this.resolvedAlbum = {
                albumName: "",
                coverLink: "",
                labelName: "",
                albumType: 1,
                releaseDate: "",
                artistNameList: [],
                albumLinkList: [],
                trackList: [],
            }
            if (!this.url.match("album") && !this.url.match("c6.y.qq.com/base")) {
                return showMessage("无效地址", "error")
            }
            if (this.url.includes("分享")) {
                let s1 = this.url;
                let s2 = s1.slice(s1.indexOf("http"));
                let s3 = s2.slice(0, s2.indexOf(" "));
                this.url = s3.slice(0, s2.indexOf(" "));
            }
            const loading = ElLoading.service({
                lock: true,
                text: "获取专辑信息中",
                background: "rgba(0, 0, 0, 0.7)",
            });
            getAlbumInfoByUrl({url: this.url})
                .then((res) => {
                    if (res.code === "0") {
                        if (isNotEmpty(res.data.existAlbumId)) {
                            showMessage("专辑已添加~", "success")
                            this.$router.push({
                                path: "/album/" + res.data.existAlbumId,
                            });
                        }
                        this.resolvedAlbum = res.data
                        this.submitAlbumDialog = true
                    } else {
                        showMessage(res.msg, "error")
                    }
                }).catch((reason) => {
                console.log(reason);
                showMessage("请求超时，解析专辑错误", "error")
            }).finally(() => {
                loading.close();
            });
        },
        handleClose() {
            this.uploadAlbumDialog = false;
            this.url = '';
        },
        handleClose2() {
            this.submitAlbumDialog = false;
        },
        submitAlbum() {
            const loading = ElLoading.service({
                lock: true,
                text: "添加专辑中",
                background: "rgba(0, 0, 0, 0.7)",
            });
            addAlbum().then((res) => {
                if (res.code !== "0") {
                    showMessage(res.msg, "error")
                } else {
                    this.uploadAlbumDialog = false;
                    this.submitAlbumDialog = false;
                    showMessage("专辑已添加~", "success")
                    this.$router.push({
                        path: "/album/" + res.data,
                    });
                }
            }).catch((reason) => {
                console.log(reason);
                showMessage("请求超时，添加专辑错误", "error")
            }).finally(() => {
                loading.close();
            });
        },
    },
};
</script>

<style lang="less" scoped>
.header {
    max-width: 1080px;
    margin: 0 auto;
    height: 2.5rem;
    display: flex;
    flex-direction: row;
    box-shadow: rgb(0 0 0 / 20%) 0 2px 1px -1px,
    rgb(0 0 0 / 14%) 0px 1px 1px 0px, rgb(0 0 0 / 12%) 0px 1px 3px 0px;

    .headerLeft {
        display: flex;
        flex-direction: row;
        flex: 1;

        .logo {
            padding-left: 1.1rem;
            height: 40px;
        }

        .site {
            font-weight: lighter;
        }
    }

    .headerRight {
        display: flex;
        flex-direction: row;

        span {
            margin-right: 20px;
            margin-top: 0.3rem;
        }

        .review_add {
            height: 40px;
        }

        .user-face {
            border-radius: 50%;
            height: 2rem;
            width: 2rem;
        }
    }

    span {
        margin-left: 15px;
        font-size: 1.8rem;
        font-weight: bolder;
    }
}

@media (max-width: 768px) {
    .header {
        height: 1.4rem;

        span {
            font-size: 0.8rem;
        }

        .headerLeft {
            .logo {
                height: 1.4rem;
            }
        }

        .headerRight {
            .review_add {
                height: 1.4rem;
            }

            .user-face {
                border-radius: 50%;
                height: 1rem;
                width: 1rem;
            }
        }
    }
}
</style>
