import request from '@/utils/request'

export function getRecAlbum(data) {
    return request({
        url: '/album/latest',
        method: 'get',
        params: data
    })
}

export function getAlbum(data) {
    return request({
        url: '/album',
        method: 'get',
        params: data
    })
}

export function addAlbum(data) {
    return request({
        url: '/album',
        method: 'post',
        data: data
    })
}

export function searchAlbum(data) {
    return request({
        url: '/album/list',
        method: 'get',
        params: data
    })
}

export function searchAlbumByGenre(data) {
    return request({
        url: 'album/list/genre',
        method: 'get',
        params: data
    })
}
