export function isEmpty(obj) {
    if (obj === null || obj === undefined) {
        return true;
    }

    if (Array.isArray(obj)) {
        return obj.length === 0;
    }

    if (typeof obj === 'string') {
        return obj.trim().length === 0;
    }

    if (typeof obj === 'object') {
        return Object.keys(obj).length === 0;
    }

    return false;
}

export function isNotEmpty(obj) {
    return !isEmpty(obj);
}


export function back2Top() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    window.pageYOffset = 0;
}

export function createFilter(queryString) {
    return (a) => {
        return (a.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
    };
}