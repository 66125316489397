import {isEmpty, isNotEmpty} from "@/utils/common-utils";

const allGenreJson = require("../assets/json/all-genre-240507.json")

export function checkLogin() {
    if (isEmpty(localStorage.getItem("userId"))) {
        return false
    }
    if (isEmpty(localStorage.getItem("userName"))) {
        return false
    }
    if (isEmpty(localStorage.getItem("userFace"))) {
        return false
    }
    return !isEmpty(localStorage.getItem("token"));

}

export function getUserId() {
    if (checkLogin()) {
        return localStorage.getItem("userId")
    } else {
        return ""
    }
}

export function getUserName() {
    if (checkLogin()) {
        return localStorage.getItem("userName")
    } else {
        return ""
    }
}

export function getUserFace() {
    if (checkLogin()) {
        return localStorage.getItem("userFace")
    } else {
        return ""
    }
}

export function getGenreName() {
    return allGenreJson
}
