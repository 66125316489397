import {ElMessage} from "element-plus";

export function showMessage(msg, type) {
    ElMessage({
        showClose: true,
        message: msg,
        center: true,
        type: type,
    });
}
