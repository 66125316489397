<template>
    <div>
        <router-view :key="$route.fullPath" />
    </div>
</template>

<style>
body {
    background-image: linear-gradient(
        to right top,
        #d16ba5,
        #c777b9,
        #ba83ca,
        #aa8fd8,
        #9a9ae1,
        #8aa7ec,
        #79b3f4,
        #69bff8,
        #52cffe,
        #41dfff,
        #46eefa,
        #5ffbf1
    );
}
button {
    font-family: sans-serif;
    margin: 0;
    height: 100vh;
}

button {
    font-family: sans-serif;
}

span {
    cursor: pointer;
}

img {
    cursor: pointer;
}

a {
    text-decoration: none;
    color: black;
}
</style>
