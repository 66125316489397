<template>
    <div>
        <Header />
        <div style="display: flex; flex-direction: column">
            <router-view />
        </div>
    </div>
</template>

<script>
import Header from "@/components/Header";

export default {
    name: "Layout",
    components: {
        Header,
    },
};
</script>
