import axios from "axios";
import qs from "qs";
import {showMessage} from "@/utils/ui/message";
import router from '@/router/index'

const request = axios.create({
    baseURL: process.env.VUE_APP_BASE_API,
    timeout: 180000,
});
// request 拦截器
// 可以自请求发送前对请求做一些处理
// 比如统一加token，对请求参数统一加密
request.interceptors.request.use(
    (config) => {
        config.headers["Content-Type"] = "application/json;charset=utf-8";
        if (config.method === "get") {
            config.paramsSerializer = function (params) {
                return qs.stringify(params, {arrayFormat: "repeat"});
            };
        }
        config.headers["token"] = localStorage.getItem("token"); // 设置请求头
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);
// response 拦截器
// 可以在接口响应后统一处理结果
request.interceptors.response.use(
    (response) => {
        let res = response.data;
        // 如果是返回的文件
        if (response.config.responseType === "blob") {
            return res;
        }
        // 兼容服务端返回的字符串数据
        if (typeof res === "string") {
            res = res ? JSON.parse(res) : res;
        }
        if (res.code === "A0200") {
            showMessage("请重新登录", "error")
            localStorage.removeItem("token");
            localStorage.removeItem("userId");
            localStorage.removeItem("userName");
            localStorage.removeItem("userFace");
            // router.push('/home')
            location.reload()
            // let username = document.getElementsByClassName(
            //     "el-tooltip__trigger"
            // );
            // username[0].innerHTML = "登录";
        }
        if (res.code === "A0300") {
            showMessage("操作频繁", "error")
            //TODO jump 2 404
            localStorage.removeItem("token");
            localStorage.removeItem("userId");
            localStorage.removeItem("userName");
            localStorage.removeItem("userFace");
            // router.push('/home')
            location.reload()
            // let username = document.getElementsByClassName(
            //     "el-tooltip__trigger"
            // );
            // username[0].innerHTML = "登录";
        }
        return res;
    },
    (error) => {
        console.log("err" + error); // for debug
        return Promise.reject(error);
    }
);

export default request;
