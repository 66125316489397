import {createRouter, createWebHistory, RouteRecordRaw} from "vue-router";
import Layout from "../layout/Layout.vue";

const routes: Array<RouteRecordRaw> = [
    {
        path: "/",
        name: "Layout",
        component: Layout,
        redirect: "/home",
        children: [
            {
                path: "/home",
                name: "HomeView",
                component: () => import("../views/HomeView.vue"),
            },
            {
                path: "/more",
                name: "MoreView",
                component: () => import("../views/MoreView.vue"),
            },
            {
                path: "/album/:id?",
                name: "AlbumView",
                component: () => import("../views/AlbumView.vue"),
            },
            {
                path: "/login",
                name: "LoginView",
                component: () => import("../views/LoginView.vue"),
            },
            {
                path: "/space/:id?",
                name: "SpaceView",
                component: () => import("../views/SpaceView.vue"),
            },
            {
                path: "/wiki/artist/:id?",
                name: "ArtistView",
                component: () => import("../views/ArtistView.vue"),
            },
        ],
    },
];

const router = createRouter({
    history: createWebHistory('/'),
    routes,
});
router.beforeEach((to, from, next) => {
    // 路由跳转后，让页面回到顶部
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    window.pageYOffset = 0;
    //next() 放行 next('/login') 强制跳转
    // 调用 next()，一定要调用 next 方法，否则钩子就不会被销毁!!
    // this.lang = to.params.lang;
    // this.setCode();
    next();
});
export default router;
